export interface CreativeLayerConfig {
  cols: number[];
  rows: number[];
  steps: number;
  grid_config: CreativeCell[];
}

export interface CreativeCell {
  pos: [number, number];
  type: CreativeCellType;
  config: any;
  step_position: number;
}

export enum CreativeCellType {
  EMPTY = "",
  PRODUCT = "product",
  TEXT = "text",
  MEDIA = "media",
  SHAPE = "bg",
  PRODUCT_ASSET = "product_asset",
}

export interface CreativeLayerSettings {
  opacity?: number | string;
  total_duration?: number | string;
  layer_offset?: number | string;
  product_rounds?: number | string;
}

export interface CreativeLayerPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface CreativeLayer {
  name: string;
  identifier: string;
  layers: CreativeLayer[];
  position?: CreativeLayerPosition;
  config?: CreativeLayerConfig;
  LAYER?: CreativeLayerSettings;
  type: CreativeLayerType;
  // Identifier of parent
  parent?: string;
}

export enum CreativeLayerType {
  LAYER = "layer",
  GROUP = "group",
}

export interface CreativeSequence {
  type: string;
  layers: CreativeLayer[];
}

export interface CreativeSpec {
  config: any;
  sections: [CreativeSequence];
}

export enum CreativeEditorMode {
  VIDEO = "video",
  IMAGE = "image",
}

export interface CreativePreviewSettings {
  selectedResolution?: string;
  resolution?: [number, number];
  editorMode?: CreativeEditorMode;
  preview_filters?: any;
}

export interface Creative {
  id?: number;
  name: string;
  spec: CreativeSpec;
  preview_settings: CreativePreviewSettings;
  live_image_cache?: any;
  num_product_cells?: number;
  ui_folder?: number;
}

// When listing all creatives
export interface ListCreative {
  id: number;
  name: string;
  thumbnail: string;
  ui_folder: number;
  num_product_cells: number;
  num_products_required: number;
}

export interface CreativeSpecItem<T> {
  name: string;
  display: string;
  default: T;
  value_type: string;
  help: {
    text: string;
    image: string;
    video: string;
  };
  hidden?: boolean;
}
