import { CreativeLayer, CreativeLayerType } from "@core/models/creative.types";

export interface BoundingBox {
  layerId?: string;
  top: number;
  left: number;
  width: number;
  height: number;
}

export function* iterateSpecificLayers(
  layers: CreativeLayer[],
  includeGroups = false,
): Generator<CreativeLayer, void, any> {
  for (const layer of layers) {
    if (layer.type === CreativeLayerType.GROUP) {
      if (includeGroups) {
        layer.parent = null;
        yield layer;
      }
      yield* layer.layers.map((l) => {
        l.parent = layer.identifier;
        return l;
      });
      continue;
    }
    layer.parent = null;
    yield layer;
  }
}

export function _sum(arr: number[]) {
  return arr.reduce((a, b) => a + b, 0);
}

export function _layerBoundingBox(layer: CreativeLayer): BoundingBox {
  const rows = layer.config.rows;
  const cols = layer.config.cols;
  const rowSum = _sum(rows);
  const colSum = _sum(cols);
  return {
    layerId: layer.identifier,
    top: layer.position?.y ?? (rows[0] / rowSum) * 100,
    left: layer.position?.x ?? (cols[0] / colSum) * 100,
    height: layer.position?.height ?? (rows[1] / rowSum) * 100,
    width: layer.position?.width ?? (cols[1] / colSum) * 100,
  };
}

export function layersBoundingBox(...layers: CreativeLayer[]): BoundingBox {
  const _layers = Array.from(iterateSpecificLayers(layers));

  // If only a single layer, calculation is simple
  if (_layers.length === 1) {
    return _layerBoundingBox(_layers[0]);
  }

  // Bounding box for a collection of layers
  const boundingBoxes = _layers.map((layer) => _layerBoundingBox(layer));

  const t = Math.min(...boundingBoxes.map((bb) => bb.top));
  const l = Math.min(...boundingBoxes.map((bb) => bb.left));
  const calculatedBB: BoundingBox = {
    top: t,
    left: l,
    width: Math.max(...boundingBoxes.map((bb) => bb.left + bb.width)) - l,
    height: Math.max(...boundingBoxes.map((bb) => bb.top + bb.height)) - t,
  };

  return calculatedBB;
}

export function commitLayerBounds(layer: CreativeLayer, bounds: BoundingBox) {
  // const r = layer.config.rows;
  // const c = layer.config.cols;
  const top = bounds.top;
  const left = bounds.left;
  const width = bounds.width;
  const height = bounds.height;

  layer.position = { x: left, y: top, width: width, height: height };

  // r[0] = top;
  // r[1] = height;
  // r[2] = granularity - (top + height);

  // c[0] = left;
  // c[1] = width;
  // c[2] = granularity - (left + width);
}

function hashCode(str): string {
  return Math.abs(
    str
      .split("")
      .reduce(
        (prevHash, currVal) =>
          ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
        0,
      ),
  ).toString();
}

export function calculateLayerHash(layer: CreativeLayer): string {
  return hashCode(JSON.stringify(layer));
}
